
import Vue from "vue";
import selectoresModule from "@/store/modules/selectoresModule";
import spinnerModule from "@/store/modules/spinnerModule";
import Selector from "@/components/selectores/selector.vue";
import { Kiosko } from "apd.models";
import { mdiMonitor } from "@mdi/js";

export default Vue.extend({
  name: "SelectCajeros",
  components: {
    Selector,
  },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        cajero: mdiMonitor,
      } as Record<string, string>,
    };
  },
  computed: {
    cajeros(): Kiosko[] {
      return selectoresModule.cajeros;
    },
    loading(): boolean {
      return selectoresModule.loading;
    },
  },
  watch: {
    loading(val: boolean) {
      if (val) {
        spinnerModule.Show();
      } else {
        spinnerModule.Hide();
      }
    },
  },
  async created() {
    if (this.cajeros.length <= 0) {
      await selectoresModule.LoadCajeros();
    }
  },
  methods: {
    change(data: string | string[]) {
      this.$emit("change", data);
    },
  },
});
